<div id="page">
    <header>
        <mat-toolbar
            fxLayout="row"
            fxLayoutGap="20px"
            [style.color]="headerColor$ | async"
            [style.background-color]="headerBackgroundColor$ | async"
        >
            <div fxLayoutAlign="center center" fxLayoutGap="15px">
                <button
                    mat-flat-button
                    class="menu-button"
                    (click)="toggleMenu()"
                >
                    <vsc-icon color="white">bars</vsc-icon>
                    {{ 'Menu' | transloco }}
                    <ng-container *ngxPermissionsOnly="Permissions.Chat.View">
                        <span
                            class="chats-count-note"
                            *ngIf="
                                pendingChatsCountNote$
                                    | async as pendingChatsCountNote
                            "
                            >{{ pendingChatsCountNote }}</span
                        >
                    </ng-container>
                </button>
            </div>
            <vsc-logo link="/home"></vsc-logo>

            <div class="widget-holder">
                <vsc-clock></vsc-clock>
            </div>
            <span style="flex: 1 1 auto"></span>
            <div fxLayoutAlign="center center" fxLayoutGap="15px">
                <button
                    class="help-font"
                    routerLink="/support-request"
                    [matTooltip]="'Help Page' | transloco"
                    *ngIf="hasViewHelpPagePermission"
                >
                    <vsc-icon color="accent">circle-question</vsc-icon>
                    <span>{{ 'Help' | transloco }}</span>
                </button>

                <vsc-lng-switcher></vsc-lng-switcher>
                <vsc-notifications-panel-trigger></vsc-notifications-panel-trigger>
                <vsc-tenant-switcher *isIdpAdmin></vsc-tenant-switcher>
                <vsc-user-menu></vsc-user-menu>
            </div>
        </mat-toolbar>
    </header>
    <nav #nav [ngClass]="!isMenuVisible ? 'hidden' : ''">
        <vsc-menu (menuItemClick)="menuItemClickAction$.next(true)"></vsc-menu>
    </nav>
    <main>
        <router-outlet></router-outlet>
    </main>
    <!-- <div class="powered-by">
        <p>
            Powered by
            <img src="../../../assets/images/enliveo-trademark.svg" /> enliveo
        </p>
    </div> -->
    <vsc-footer></vsc-footer>
</div>
